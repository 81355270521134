import 'react-app-polyfill/ie11';

import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import { ActionContext } from '../cra';
import IframeVideo from '../components/iframeVideo';
import Layout from '../components/layout';
import SEO from '../components/seo';
import checkThanks from '../images/check-thanks.svg';
import telephone from '../images/telephone.svg';

function Thanks() {
  const [notIE, setNotIE] = useState(false);

  if (typeof window !== 'undefined' && notIE) {
    window.g3cm =
      window.g3cm ||
      function() {
        // eslint-disable-next-line no-extra-semi
        (window.g3cm.q = window.g3cm.q || []).push(arguments);
      };
    window.g3cm('loadjquery', 'true');
    const phone = document.cookie.match(/leadphone=(\d+);?/);
    if (phone) {
      window.g3cm('phonenumber', phone[1]);
      window.g3cm('send', 'init');
    }
  }

  const { trackCurrentPage } = React.useContext(ActionContext);
  React.useEffect(() => {
    trackCurrentPage('Thanks Page');
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onbeforeunload = null;
      window.sessionStorage.removeItem('pf_state');
      window.sessionStorage.removeItem('pf_cookies');
      window.sessionStorage.removeItem('pf_lead_data');
      window.sessionStorage.removeItem('sf_state');
      window.sessionStorage.removeItem('sf_params');

      if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
        return;
      } else {
        setNotIE(true);
      }
    }
  }, []);

  return (
    <Layout stickyFooter={false}>
      <SEO title='Thanks' />
      {notIE && (
        <script
          src='https://api.connectstreams.com/js/connectme-v3.min.js'
          async='async'
        ></script>
      )}
      <section className='common-section thanks-sec1'>
        <Container>
          <Row className='align-items-center text-center'>
            <Col xs={12}>
              <div className='thanks-sec1-wrap'>
                <img src={checkThanks} alt='Payment img' className='com-img' />
                <h2>Thank you for your inquiry to MedicareFAQ.</h2>
                <p className='p18 f-600 xs-p14'>
                  Wow...there were over <b className='text-red'>250 plans</b>{' '}
                  and carriers found in your area!
                </p>
                <p className='p16 f-400 xs-p12'>
                  We have one of our licensed agents working on your rate
                  comparison now. <br />
                  Please give us a call so we can email or mail them to you.
                </p>
                <h4 className='text-red'>
                  <img src={telephone} alt='Telephone' className='mr20' />
                  <a
                    className='text-red'
                    href='tel:+18883799437'
                    id='callInNum'
                  >
                    <span className='text-red h4'>(888) 379-9437</span>
                  </a>
                </h4>
              </div>
              <div
                className='g3cm_holder'
                data-module='1387'
                data-showoninit='true'
                style={{ display: 'none' }}
              >
                Thank you for your inquiry. A representative will be in contact
                with you shortly.
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='common-section thanks-sec2'>
        <Container>
          <Row className='align-items-center text-center'>
            <Col xs={12}>
              <p className='p20 f-600 xs-p14'>
                If you have an extra couple of minutes, please watch the video
                below
              </p>
              <IframeVideo />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
export default Thanks;
